import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ifvisible from 'ifvisible.js';
import { useDispatch, useSelector } from 'react-redux';
import { getNewChapterContentsProgress, saveNewProgress } from "../../../redux/slices/newProgress";

const PaidPlayer = (props) => {
  const { courseProgress, newcourseProgress } = props;
  const { courses, recordings } = useSelector((state) => state.courses)
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.user);
  const { currentLesson, data, chapterId } = props;
  const otp = currentLesson && currentLesson.otp;
  const playbackInfo = currentLesson && currentLesson.playbackInfo;
  const contentId = data && data.id;
  const viewToken = null;
  const isCompleted = courseProgress && courseProgress.length > 0 && courseProgress.find((p) => p.content_id === contentId);
  const subjectId = courses ? courses.subject.id : recordings.subject.id
  const isFree = permissions?.freeHours == null ? false : true;
  const [socket, setSocket] = useState(null);
  const { informations } = useSelector((state) => state.user);
  const intervalIdRef = useRef(null);
  const socketRef = useRef(null);
  const token = localStorage.getItem("token")
  useEffect(() => {
    const ws = new WebSocket(`wss://progressv2.takiacademy.com/ws/progress?content_id=${currentLesson?.content?.id}&token=${token}`);
    socketRef.current = ws;

    ws.onmessage = (message) => {
      console.log("Received WebSocket message:", message.data);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = (event) => {
      console.warn("WebSocket connection closed:", event.code, event.reason);
    };

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [currentLesson, informations]);

  const sendWebSocketMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.warn("WebSocket is not open");
    }
  };

  useEffect(() => {
    if (data && playbackInfo) {
      const video = new window.VdoPlayer({
        otp: otp,
        playbackInfo: playbackInfo,
        theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
        container: document.querySelector('#embedBox'),
        plugins: [{ name: 'keyboard', options: { preset: 'default' } }],
      });

      const handleProgress = () => {
        console.log("Tracking progress... Duration:", video.currentTime);
        sendWebSocketMessage({
          progress: String(Math.round(video.currentTime)),
          state: "playing",
        });
      };

      const startProgressTracking = () => {
        if (intervalIdRef.current === null) {
          intervalIdRef.current = setInterval(handleProgress, 5000);
        }
      };

      const stopProgressTracking = () => {
        if (intervalIdRef.current !== null) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      };

      video.addEventListener("play", () => {
        console.log("Video started playing");
        startProgressTracking();
      });

      video.addEventListener("pause", () => {
        console.log("Video paused");
        stopProgressTracking();
        sendWebSocketMessage({
          progress: String(Math.round(video.currentTime)),
          state: "paused",
        });
      });
      video.addEventListener("seeked", () => {
        setTimeout(() => {
          sendWebSocketMessage({
            progress: String(Math.round(video.currentTime)),
            state: "skipped_forward",
          });
        }, 10);
      });


      return () => {
        stopProgressTracking();

      };
    }
  }, [data, otp, playbackInfo]);


  return (
    <div>
      {data && (
        <div className="rounded">
          <div id="embedBox" className="embed-responsive-item"></div>
        </div>
      )}

    </div>
  );
};

PaidPlayer.propTypes = {
  data: PropTypes.object,
  otp: PropTypes.any,
  playbackInfo: PropTypes.any,
  update: PropTypes.bool,
};

export default PaidPlayer;
