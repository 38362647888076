import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import api from '../../services/api';

// Slice
const slice = createSlice({
  name: 'progress',
  initialState: {
    newsubjectsProgress: 0,
    newsubjectProgress: 0,
    newchaptersProgress: 0,
    newchapterProgress: 0,
    newcourseProgress: 0,
    newisSubjectsProgressLoading: false,
    newisSubjectProgressLoading: false,
    newisChaptersProgressLoading: false,
    newisChapterProgressLoading: false,
    newisCourseProgressLoading: false,
    error: false,
    freeDurationPercentage: 0,
  },
  reducers: {
    startNewSubjectsLoading: (state) => {
      state.newisSubjectsProgressLoading = true;
    },
    startNewSubjectLoading: (state) => {
      state.newisSubjectProgressLoading = true;
    },
    startNewChaptersLoading: (state) => {
      state.newisChaptersProgressLoading = true;
    },
    startNewChapterLoading: (state) => {
      state.newisChapterProgressLoading = true;
    },
    startNewCourseLoading: (state) => {
      state.newisCourseProgressLoading = true;
    },
    hasNewError: (state, action) => {
      state.error = action.payload;
      state.newisSubjectsProgressLoading = false;
    },
    NewSubjectsProgressSuccess: (state, action) => {
      state.newsubjectsProgress = action.payload.data;
      state.newisSubjectsProgressLoading = false;
    },
    NewSubjectProgressSuccess: (state, action) => {
      state.newsubjectProgress = action.payload.data;
      state.newisSubjectProgressLoading = false;
    },
    NewChaptersProgressSuccess: (state, action) => {
      state.newchaptersProgress = action.payload.data;
      state.newisChaptersProgressLoading = false;
    },
    NewChapterProgressSuccess: (state, action) => {
      state.newchapterProgress = action.payload;
      state.newisChapterProgressLoading = false;
    },
    NewCourseProgressSuccess: (state, action) => {
      state.newcourseProgress = action.payload.data;
      state.newisCourseProgressLoading = false;
    },
    NewSavedProgress: (state, action) => {
      state.newprogressExpired = action.payload.data.expired;
    },
    FreeDurationPercentage: (state, action) => {
      state.freeDurationPercentage = action.payload.data?.data?.percentage;
    },
  },
});

//const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL;
const analyticsUrlV2 = process.env.REACT_APP_ANALYTICS_URL_V2;
const apiKey = process.env.REACT_APP_X_API_KEY;
const newAnalytics = process.env.REACT_APP_ANALYTICS_URL_NEW_V2;
export const token = localStorage.getItem('token');
export const token_type = localStorage.getItem('token_type');

export default slice.reducer;
// Actions
const {
  NewSubjectsProgressSuccess,
  NewSubjectProgressSuccess,
  NewChaptersProgressSuccess,
  ChapterProgressSuccess,
  startNewSubjectsLoading,
  startNewSubjectLoading,
  startNewChaptersLoading,
  startNewChapterLoading,
  startNewCourseLoading,
  NewCourseProgressSuccess,
  hasNewError,
  NewSavedProgress,
  FreeDurationPercentage,
} = slice.actions;

export const saveNewProgress =
  ({ viewToken, contentId, chapterId, subjectId, completed, totalWatched, watchedTime, isFree }) =>
    async (dispatch) => {
      try {
        //   debugger
        await api
          .post(
            `${analyticsUrlV2}/progress`,
            {
              viewToken: viewToken,
              contentId,
              chapterId,
              subjectId,
              completed,
              totalWatched: totalWatched,
              watchedTime,
              isFree,
            },
            {
              headers: {
                'X-Api-Key': apiKey,
                'AuthorizationUser': `${token_type} ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.expired) {
              dispatch(NewSavedProgress(response));
            }
          })
          .catch((e) => {
            //return console.error(e.message)
            if (e?.response?.status === 403) {
              const adminID = localStorage.getItem('admin_user_id');
              if (adminID && adminID.includes('?redirectTo=RegisterPage')) {
                window.location = 'https://education-autrement.takiacademy.com/register';
                localStorage.removeItem('admin_user_id');
              }
            } else {
              //localStorage.clear();
              //window.location = '/login';
            }
          });
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getNewSubjectsProgress = () => async (dispatch) => {
  dispatch(startNewSubjectsLoading());
  console.log("ddds")
  try {
    await api
      .get(`${analyticsUrlV2}/progress/subjects`, {
        headers: {
          'X-Api-Key': apiKey,
          'Authorizationuser': `${token_type} ${token}`,
        },
      })
      .then((response) => dispatch(NewSubjectsProgressSuccess(response.data)));
    // await Axios.get(`${newAnalytics}/progress/subjects`, {
    //   headers: {
    //     'Authorization': `${token_type} ${token}`,
    //   },

    // }).then((response) => dispatch(NewSubjectsProgressSuccess(response.data)));

  } catch (e) {
    return dispatch(hasNewError(e.message));
  }
};
export const getNewSubjectProgress = (subject_id) => async (dispatch) => {
  dispatch(startNewSubjectLoading());
  try {
    await api
      .get(`${analyticsUrlV2}/progress/subjects/${subject_id}`, {
        headers: {
          'X-Api-Key': apiKey,
          'AuthorizationUser': `${token_type} ${token}`,
        },
      })
      .then((response) => dispatch(NewSubjectProgressSuccess(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getNewSubjectChaptersProgress = (subject_id) => async (dispatch) => {
  dispatch(startNewChaptersLoading());
  try {
    await api
      .get(`${analyticsUrlV2}/progress/subjects/${subject_id}/chapters`, {
        headers: {
          'X-Api-Key': apiKey,
          'AuthorizationUser': `${token_type} ${token}`,
        },
      })
      .then((response) => dispatch(NewChaptersProgressSuccess(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getNewChapterContentsProgress = (chapter_id, clearCache) => async (dispatch) => {
  dispatch(startNewCourseLoading());
  try {
    await api
      .get(`${analyticsUrlV2}/progress/chapters/${chapter_id}/contents`, {
        headers: {
          'X-Api-Key': apiKey,
          'AuthorizationUser': `${token_type} ${token}`,
        },
      })
      .then((response) => dispatch(NewCourseProgressSuccess(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUserFreeDuration = (totalDuration) => async (dispatch) => {
  try {
    await api
      .get(`${analyticsUrlV2}/free-offer-duration/used-duration?duration=${totalDuration}`, {
        headers: {
          'X-Api-Key': apiKey,
          'AuthorizationUser': `${token_type} ${token}`,
        },
      })
      .then((response) => dispatch(FreeDurationPercentage(response)));
  } catch (e) {
    return console.error(e.message);
  }
};
