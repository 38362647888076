import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { CircularProgress, Dialog, DialogContent, InputLabel } from '@material-ui/core';

import { enqueueSnackbar } from '../../../redux/slices/notifier';
import {
  getReportReasons,
  sendVideoReport,
  updateVideoReport,
} from '../../../redux/slices/courses';

const VideoReportModal = ({ id, open, handleClose, data, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const content = data?.edit ? data?.contentId : data?.lessonSlug?.split('-')[0];
  const currentReason = data?.edit ? data?.reasonId : '';
  const currentDescription = data?.edit ? data?.report : '';
  const [reportReason, setReason] = useState(currentReason);
  const { reasons, isReportLoading } = useSelector((state) => state.courses);
  const [description, setDescription] = useState(currentDescription);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  useEffect(() => {
    if (!reasons?.length && open) {
      dispatch(getReportReasons());
    }
  }, [open]);

  useEffect(() => {
    if (data?.edit) {
      setReason(currentReason);
      setDescription(currentDescription);
    }
  }, [data?.id]);

  const handleSubmit = () => {
    if (reportReason === '' || description === '' || data?.reasonId === '' || data?.report === '') {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir les champs raison et description'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        })
      );
      return;
    }
    if (data?.edit) {
      dispatch(
        updateVideoReport(
          data?.id,
          content,
          reportReason || data?.reasonId,
          description || data?.report
        )
      );
    } else {
      dispatch(sendVideoReport(content, reportReason, description));
    }
    setOpenSnackBar(true);
    setReason('');
    setDescription('');
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(e, reason) => handleClose(id)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-modal"
      >
        <DialogTitle>
          <span className="label">{t('signalement d erreur')}</span>
          <span className="close-btn" onClick={() => handleClose(id)}>
            {t('Fermer')}
          </span>
        </DialogTitle>
        <DialogContent className="ta-modal-content-scroll">
          <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
            {data?.edit && (
              <TextField
                fullWidth
                disabled
                id="contentName"
                value={data?.name}
                variant="outlined"
                style={{ marginTop: 10 }}
              />
            )}

            <InputLabel htmlFor="division" className="fix-display">
              {t('Raison du signalement')} <span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Select
              fullWidth
              label={t('Raison du signalement')}
              name="reportReason"
              variant="outlined"
              id="reportReason"
              labelId="demo-simple-select-outlined-label"
              value={reportReason}
              onChange={(e) => setReason(e.target.value)}
            >
              {reasons.map((reason) => {
                return <MenuItem value={reason.id}>{t(reason.name)}</MenuItem>;
              })}
            </Select>
            <TextField
              fullWidth
              id="description"
              value={description}
              label={t('Précisez le type exact d erreur ou de déficience, s il vous plait')}
              variant="outlined"
              onChange={(e) => setDescription(e.target.value)}
              style={{ marginTop: 10 }}
            />
            <Box mt={2} className="ta-btns-group rtl">
              <Button
                onClick={(event) => handleSubmit(event)}
                className="ta-btn primary btn-rounded btn-xl"
              >
                {isReportLoading ? (
                  <CircularProgress style={{ color: 'white' }} size={20} />
                ) : (
                  t('soumettre')
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoReportModal;
